import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ApiText = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [apiText, setApiText] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: 'http://hdqlab/blue-wren/api',
          scope: '',
        });
        const response = await fetch('https://fg2lahd41d.execute-api.ap-southeast-2.amazonaws.com/blue_wren_api_stage/info', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setApiText(await response.json().message);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  if (!apiText) {
    return <div>Loading...</div>;
  }

  return (
    <p>
      ApiText was:
      {apiText}
    </p>
  );
};

export default ApiText;
